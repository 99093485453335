/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    hammer: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.972 2.508a.5.5 0 00-.16-.556l-.178-.129a5 5 0 00-2.076-.783C6.215.862 4.504 1.229 2.84 3.133H1.786a.5.5 0 00-.354.147L.146 4.567a.5.5 0 000 .706l2.571 2.579a.5.5 0 00.708 0l1.286-1.29a.5.5 0 00.146-.353V5.57l8.387 8.873A.5.5 0 0014 14.5l1.5-1.5a.5.5 0 00.017-.689l-9.129-8.63c.747-.456 1.772-.839 3.112-.839a.5.5 0 00.472-.334"/>',
    },
});
